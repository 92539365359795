import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetCompliancesList, AddCompliance, ListCompliance } from '../store/slices/reports';
import Loader from '../common/Loader';
import AdminLayout from './AdminLayout';

const Compliance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openFormId, setOpenFormId] = useState(null);
  const [compliancesList, setCompliancesList] = useState([]);
  const [listCompliance, setListCompliance] = useState([]);

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;

  const [formData, setFormData] = useState({
    date: '',
    document: null,
    compliance_id: '',
    compliance_name: '',
    remarks: '',
    next_due_date: '',
    is_completed: false, // Default to false
  });

  const [errors, setErrors] = useState({
    date: '',
    document: '',
    compliance_name: '',
    remarks: '',
    next_due_date: '',
  });

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(GetCompliancesList({ profile_id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setCompliancesList(data?.data);
        console.log(data.data);
        data?.data?.forEach((compliance) => {
            handleComplianceSelect(compliance.id); // Make the API call for each compliance
          });
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  }, []);

  const handleComplianceSelect = (complianceid) => {
    setIsLoading(true);
    dispatch(ListCompliance({ profile_id: id, compliance_id: complianceid }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        // Avoid duplicates by checking if the complianceId already exists in listCompliance
        setListCompliance((prevList) => {
          const existingCompliance = prevList.find(item => item.complianceId === complianceid);
          if (!existingCompliance) {
            return [...prevList, { complianceId: complianceid, data: data?.data }];
          }
          return prevList; // Don't add again if already present
        });
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  };
  


  const handleToggleForm = (id) => {
    setOpenFormId((prevId) => (prevId === id ? null : id)); // Toggle the form for the clicked compliance
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'document') {
      setFormData((prev) => ({
        ...prev,
        [name]: files[0], // Handle file input
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value, // Handle text and date inputs
      }));
    }

    // Clear errors on input change
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.date) {
      newErrors.date = 'Date is required';
      isValid = false;
    }

    if (!formData.document) {
      newErrors.document = 'Document upload is required';
      isValid = false;
    }

    if (!formData.compliance_name) {
      newErrors.compliance_name = 'Compliance name is required';
      isValid = false;
    }

    if (!formData.remarks) {
      newErrors.remarks = 'Remarks are required';
      isValid = false;
    }

    if (!formData.next_due_date) {
      newErrors.next_due_date = 'Next due date is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e, complianceId, complianceName, is_completed) => {
    e.preventDefault();

    const formDataObj = new FormData();
    formDataObj.append('profile_id', id);
    formDataObj.append('compliance_id', complianceId);
    formDataObj.append('compliance_date', formData.date);
    formDataObj.append('compliance_document', formData.document);
    formDataObj.append('remarks', formData.remarks);
    formDataObj.append('next_due_date', formData.next_due_date);
    formDataObj.append('is_completed', is_completed);
    formDataObj.append('compliance_name', complianceName);

    console.log('FormData object', formDataObj);

    setIsLoading(true);
    dispatch(AddCompliance(formDataObj))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setFormData({
          date: '',
          document: null,
          compliance_id: '',
          compliance_name: '',
          remarks: '',
          next_due_date: '',
          is_completed: false,
        });
        console.log('Compliance added successfully:', data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  };

  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="row content-body">
        <div className="container">
          <div className="page-header">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">One Time Compliance</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form>
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="form-group">
                      {compliancesList
                        ?.filter((compliance) => compliance?.is_recurring === '0')
                        ?.map((compliance) => (
                          <div className="row align-items-center mt-4 mb-4" key={compliance.id} >
                            <div className="col-md-12">
                              <h3 style={{ fontWeight: '300' }}>{compliance?.compliance_name}</h3>
                            </div>
                            <div className="col-md-5">
                              <label>
                                Date <span className="required text-danger">*</span>
                              </label>
                              <input name="date" type="date" className={`form-control ${errors.date ? 'is-invalid' : ''}`} value={formData.date} onChange={handleInputChange} />
                              {errors.date && <span className="text-danger">{errors.date}</span>}
                            </div>
                            <div className="col-md-5">
                              <label>
                                Document Upload <span className="required text-danger">*</span>
                              </label>
                              <input name="document" type="file" className={`form-control ${errors.document ? 'is-invalid' : ''}`} onChange={handleInputChange} />
                              {errors.document && <span className="text-danger">{errors.document}</span>}
                            </div>
                            <div className="col-md-2 " style={{ paddingTop: '30px' }}>
                              <button type="button" className="btn btn-default" onClick={(e) => handleSubmit(e, compliance.id, compliance.compliance_name, compliance.is_recurring)}>
                                Submit
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Recurring Compliance Section */}
        <div className="container mb-4">
          <div className="page-header">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Recurring Compliance</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form>
                {compliancesList
                  ?.filter((compliance) => compliance?.is_recurring === '1')
                  ?.map((compliance, index) => {
                    const isOpen = openFormId === compliance.id; // Check if this form is open
                    return (
                      <div className="card custom-card mb-4" key={index} onChange={() => handleComplianceSelect(compliance.id)}>
                        <div className="card-body">
                          <div className="form-group">
                            <div className="row align-items-center mt-4 mb-4">
                              <div className="col-md-12">
                                <h3 style={{ fontWeight: '300' }}>{compliance?.compliance_name}</h3>
                              </div>
                              <div className="col-md-12 mt-2">
                                <button type="button" className="btn btn-default" onClick={() => handleToggleForm(compliance.id)}>
                                  Mark As Complete
                                </button>
                              </div>

                              {isOpen && (
                                <>
                                  <div className="col-md-4 mt-3">
                                    <label>
                                      Date of Compliance <span className="required text-danger">*</span>
                                    </label>
                                    <input name="date" type="date" className="form-control" value={formData.date} onChange={handleInputChange} />
                                  </div>
                                  <div className="col-md-4 mt-3">
                                    <label>
                                      Document Upload <span className="required text-danger">*</span>
                                    </label>
                                    <input name="document" type="file" className="form-control" onChange={handleInputChange} />
                                  </div>
                                  <div className="col-md-4 mt-3">
                                    <label>
                                      Next Due Date <span className="required text-danger">*</span>
                                    </label>
                                    <input name="next_due_date" type="date" className="form-control" value={formData.next_due_date} onChange={handleInputChange} />
                                  </div>
                                  <div className="col-md-12 mt-3">
                                    <label>
                                      Remark <span className="required text-danger">*</span>
                                    </label>
                                    <textarea name="remarks" className="form-control" rows="3" value={formData.remarks} onChange={handleInputChange}></textarea>
                                  </div>
                                  <div className="col-md-2 mt-3">
                                    <button type="button" className="btn btn-default" onClick={(e) => handleSubmit(e, compliance.id, compliance.compliance_name, compliance.is_recurring)}>
                                      Save
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* Table to display compliance details */}
                            <div className="table-responsive mt-4">
                              <table className="table table-bordered">
                                <thead className="thead-light">
                                  <tr>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Document</th>
                                    <th>Remark</th>
                                    <th>Next Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {
     listCompliance
     ?.filter((complianceData) => complianceData.complianceId === compliance.id)
     ?.map((complianceData, index) => (
       complianceData?.data?.map((compliance, idx) => (
         <tr key={idx}>
           <td>{compliance.id}</td>
           <td>{compliance.compliance_date || 'Not Provided'}</td>
           <td>
             {compliance.compliance_document ? (
               <a href={compliance.compliance_document} target="_blank" rel="noopener noreferrer">
                 View Document
               </a>
             ) : (
               'Not Uploaded'
             )}
           </td>
           <td>{compliance.remarks || 'No Remarks'}</td>
           <td>{compliance.next_due_date || 'Not Provided'}</td>
         </tr>
       ))
     ))
   
  }

                        </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </form>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Compliance;
