import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { listPaymentReceiptContra, PaymentContraDelete } from '../store/slices/journal';
import Loader from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../common/Table';
import AdminLayout from './AdminLayout';

const PaymentReceipt = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [VoucherList, setVoucherList] = useState([]);
  const [columns, setcolumns] = useState([
    { header: 'Type', field: 'type' },
    { header: 'Payment Name', field: 'payment_name' },
    { header: 'Payment Date', field: 'payment_date' },
    { header: 'Debit Amount', field: 'debit_amount' },
    { header: 'Credit Amount', field: 'credit_amount' },
    {
      header: 'Actions',
      field: 'actions',
      isAction: true,
      actionButtons: [{ name: 'Edit', className: 'btn-default' }, { name: 'Delete', className: 'btn-cancel' },],
    },
  ]);

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (item) => {
    navigate(`/payment-receipt-contra/edit/${item.id ? item.id : null}`);
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Journal Voucher?');
    if (confirmDelete) {
      setIsLoading(true);
      dispatch(PaymentContraDelete({profile_id:id, voucher_id: item.id,type:item.type }))
        .unwrap()
        .then((data) => {
          setIsLoading(false);
          hindleReturn();
        })
        .catch(({ message }) => {
          setIsLoading(false);
          console.log(message);
        });
    } else {
      console.log('Deletion canceled');
    }
  };

  const hindleReturn = () => {
    setIsLoading(true);
    dispatch(listPaymentReceiptContra({ profile_id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setVoucherList(data?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  };

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(listPaymentReceiptContra({ profile_id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setVoucherList(data?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const search = searchQuery?.toLowerCase() || '';
  // Filter data based on search query
  const filteredParties = VoucherList?.filter(
    (party) =>
      party?.payment_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.type?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.payment_date?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      String(party?.debit_amount || '')
        .toLowerCase()
        .includes(search) ||
      party?.credit_amount?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="row content-body">
        <div className="container-fluid">
          <div className="page-header">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Payment/Receipt/Contra</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">List</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  List
                </li>
              </ol>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn ripple btn-default" onClick={() => navigate('/payment-receipt-contra/add', { state: { type: 'Payment' } })}>
                Add Payment
              </button>
              <button className="btn ripple btn-default" onClick={() => navigate('/payment-receipt-contra/add', { state: { type: 'Receipt' } })}>
                Add Receipt
              </button>
              <button className="btn ripple btn-default" onClick={() => navigate('/payment-receipt-contra/add', { state: { type: 'Contra' } })}>
                Add Contra
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                columns={columns}
                data={filteredParties}
                tableRef={tableRef}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                totalCount={filteredParties.length}
                onPageChange={handlePageChange}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSearchChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default PaymentReceipt;
