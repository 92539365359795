import React, { useState, useEffect ,useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { GetCreditNotelist ,creditNoteDelete } from '../store/slices/sale';
import Loader from '../common/Loader';
import { ListParties } from '../store/slices/parties';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Table from '../common/Table';
import AdminLayout from './AdminLayout';

const CreditNoteList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef=useRef(null)

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;
  const Name = user?.data?.company_name;

  const [isLoading, setIsLoading] = useState(false);
  const [creditNotelist, setCreditNotelist] = useState([]);
    const [listParties, setListParties] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedParty, setSelectedParty] = useState(null); // For ledger_id
  const [columns, setcolumns] = useState([
    { header: 'Credit Note Number', field: 'credit_note_number' },
    { header: 'Invoice Number', field: 'invoice_id' },
    { header: 'Customer', field: 'party_name' },
    {header: 'Credit Note Date', field: 'credit_note_date' },
    { header: 'Amount', field: 'grand_total' },
    { 
      header: 'Actions', 
      field: 'actions', 
      isAction: true, 
      actionButtons: [
        { name: 'Edit', className: 'btn-default' }, 
        { name: 'Delete', className: 'btn-cancel' }
      ]
    }
  ]);
 

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const partyOptions = listParties.map((party) => ({
    value: party.id,
    label: party.ledger,
  }));


    const fetchParties = async () => {
      try {
        const data = await dispatch(ListParties({ profile_id: id })).unwrap();
        setListParties(data?.data);
      } catch (error) {
        console.log('Error fetching parties:', error.message);
      }
    };
  
    useEffect(() => {
      fetchParties();
      fetchCreditNotes();
    }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (item) => {
    if (item && item.id) {
      navigate(`/creditnote/edit/${item.id}`);
    } else {
      console.log("Item or item.id is undefined");
    }
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Credit Note?");
    if (confirmDelete) {
          setIsLoading(true);
          dispatch(creditNoteDelete({ id: item.id }))
          .unwrap()
          .then((data) => {
              setIsLoading(false);
              hindleReturn();
          })
          .catch(({ message }) => {
            setIsLoading(false);
            console.log(message);
          });
    } else {
      console.log("Deletion canceled");
    }
  };

  const hindleReturn = () => {
    setIsLoading(true);
    dispatch(GetCreditNotelist({ profile_id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setCreditNotelist(data?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  };


  const fetchCreditNotes = async () => {
    setIsLoading(true);
    const params = {
      profile_id: id,
      from_date: fromDate || "", // Use fromDate if provided, else default to empty
      to_date: toDate || "",     // Use toDate if provided, else default to empty
      ledger_id: selectedParty?.value || "", // Get ledger ID from the selected party
    };
  
    try {
      const data = await dispatch(GetCreditNotelist(params)).unwrap();
      setCreditNotelist(data?.data || []);
    } catch (error) {
      console.error('Error fetching credit notes:', error.message);
      setCreditNotelist([]);
    } finally {
      setIsLoading(false);
    }
  };
  


  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchCreditNotes();
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filtereditemList = creditNotelist?.filter(party => 
    party?.credit_note_number?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
    party?.invoice_id?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
    party?.party_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())||
    party?.grand_total?.toLowerCase()?.includes(searchQuery?.toLowerCase())||
    party?.credit_note_date?.toLowerCase()?.includes(searchQuery?.toLowerCase())

  );


  return (
    <AdminLayout>
        {isLoading && <Loader />}
          <div className="row content-body">
            <div className="container-fluid">
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Credit Note/Return</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Sale</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Credit Note/Return List
                    </li>
                  </ol>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn ripple btn-default" onClick={() => navigate('/addcreditnote')}>
                    Add Credit Note/Return
                  </button>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="card custom-card">
                    <div class="card-body">
          <form onSubmit={handleFilterSubmit} className="row mb-4">
            <div className="col-md-3">
              <label>Customer</label>
              <Select
                options={partyOptions}
                placeholder="--Select Customer--"
                value={selectedParty}
                onChange={setSelectedParty}
              />
            </div>
            <div className="col-md-3">
              <label>From Date</label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <label>To Date</label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div className="col-md-3 mt-2">
              <button type="submit" className="btn btn-default mt-4">
                Submit
              </button>
            </div>
          </form>
          </div>
          </div>
        </div>
      </div>

              <div className="row">
                <div className="col-md-12">
                 <Table
                    columns={columns}
                    data={filtereditemList}
                    tableRef={tableRef}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    currentPage={currentPage}
                    totalCount={filtereditemList?.length}
                    onPageChange={handlePageChange}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleSearchChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
  );
};

export default CreditNoteList;
