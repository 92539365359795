import React, { useState, useEffect } from 'react';
import { useNavigate ,useLocation } from 'react-router-dom';
import { ListParties } from '../store/slices/parties';
import { AddPaymentReceiptContra, GetCashLedgerId } from '../store/slices/journal';
import { Getcompanybanks } from "../store/slices/bankbook";
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import AdminLayout from './AdminLayout';

import Loader from '../common/Loader';

const PaymentReceiptContraAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const { type } = location.state || {}; 

  console.log("type",type)


  const [isLoading, setIsLoading] = useState(false);
  const [listParties, setListParties] = useState([]);
  const [listPartiesNew, setListPartiesNew] = useState([]);
  const [getCompanyBanks, setGetCompanyBanks] = useState([]);
  const [getCashLedgerId, setGetCashLedgerId] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;
  const currentDate = new Date().toISOString().split('T')[0];

  const [formData, setFormData] = useState({
    profile_id: id,
    type: type,
    voucher_date: currentDate,
    voucher_name: '',
    debit_entries: [],
    credit_entries: [],
  });

  const [debitEntry, setDebitEntry] = useState({
    debit_ledger_id: null,
    debit_amount: '',
  });

  const [creditEntry, setCreditEntry] = useState({
    credit_ledger_id: null,
    credit_amount: '',
  });

  const [errors, setErrors] = useState({});

  const partyOptions = listParties.map((party) => ({
    value: party.id,
    label: party.ledger,
  }));


  const partyOptionsNew = listPartiesNew.map((party) => ({
    value: party.id,
    label: party.name,
  }));

  // Fetch parties
  useEffect(() => {
    setIsLoading(true);
    dispatch(ListParties({ profile_id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setListParties(data?.data);
        // console.log(data?.data)
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  }, [dispatch, id]);



  const fetchAndMergeData = async () => {
    try {
      setIsLoading(true);
  
      const [companyBanksResponse, partiesResponse] = await Promise.all([
        dispatch(Getcompanybanks({ profile_id: id })).unwrap(),
        dispatch(GetCashLedgerId({ profile_id: id })).unwrap(),
      ]);
  
      // console.log('Fetched Company Banks:', companyBanksResponse);
      // console.log('Fetched Parties:', partiesResponse);
  
      const mergedData = [];
  
      if (partiesResponse?.status && partiesResponse?.cash_ledger_id) {
        mergedData.push({
          id: partiesResponse.cash_ledger_id,
          name:"Cash Book",
        });
      }
  
      if (companyBanksResponse?.status && Array.isArray(companyBanksResponse.data)) {
        companyBanksResponse.data.forEach((bank) => {
          mergedData.push({
            id: bank.id,
            name: bank.ledger,
          });
        });
      }
  
      setGetCompanyBanks(companyBanksResponse?.data || []);
      setGetCashLedgerId(partiesResponse?.cash_ledger_id || ''); 
      // localStorage.setItem('mergedData', JSON.stringify(mergedData));
  
      console.log('Merged Data Stored in localStorage:', mergedData);
      setListPartiesNew(mergedData);
      
    } catch (error) {
      console.log('Error fetching and merging data:', error?.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (formData.type){
      fetchAndMergeData();
    }else{
      navigate(-1)
    }
   
  }, [dispatch, id]);

  // Handle voucher date and name changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle debit entry change
  const handleDebitChange = (field, value) => {
    setDebitEntry((prevEntry) => ({
      ...prevEntry,
      [field]: value,
    }));
  };

  // Handle credit entry change
  const handleCreditChange = (field, value) => {
    setCreditEntry((prevEntry) => ({
      ...prevEntry,
      [field]: value,
    }));
  };

  // Add debit entry to list
  const addDebitEntry = () => {
    setFormData((prevData) => ({
      ...prevData,
      debit_entries: [...prevData.debit_entries, debitEntry],
    }));

    // Reset the debit entry (both ledger and amount)
    setDebitEntry({
      debit_ledger_id: null,
      debit_amount: '',
    });
  };

  const addCreditEntry = () => {
    setFormData((prevData) => ({
      ...prevData,
      credit_entries: [...prevData.credit_entries, creditEntry],
    }));

    // Reset the credit entry (both ledger and amount)
    setCreditEntry({
      credit_ledger_id: null,
      credit_amount: '',
    });
  };

    // Determine Debit and Credit options based on transaction type
    const getDebitOptions = () => {
      switch (formData.type) {
        case 'Payment':
          return partyOptions;  // Payment: Debit = partyOptionsNew
        case 'Receipt':
          return partyOptionsNew;     // Receipt: Debit = partyOptions
        case 'Contra':
          return partyOptionsNew;  // Contra: Debit = partyOptionsNew
        default:
          return partyOptions;
      }
    };



  const getCreditOptions = () => {
    switch (formData.type) {
      case 'Payment':
        return partyOptionsNew;     // Payment: Credit = partyOptions
      case 'Receipt':
        return partyOptions;  // Receipt: Credit = partyOptionsNew
      case 'Contra':
        return partyOptionsNew;  // Contra: Credit = partyOptionsNew
      default:
        return partyOptions;
    }
  };

  // Remove debit entry
  const removeDebitEntry = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      debit_entries: prevData.debit_entries.filter((_, i) => i !== index),
    }));
  };

  // Remove credit entry
  const removeCreditEntry = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      credit_entries: prevData.credit_entries.filter((_, i) => i !== index),
    }));
  };

  // Edit debit entry
  const handleDebitEdit = (index, field, value) => {
    const updatedDebitEntries = formData.debit_entries.map((entry, i) => (i === index ? { ...entry, [field]: value } : entry));
    setFormData((prevData) => ({
      ...prevData,
      debit_entries: updatedDebitEntries,
    }));
  };

  // Edit credit entry
  const handleCreditEdit = (index, field, value) => {
    const updatedCreditEntries = formData.credit_entries.map((entry, i) => (i === index ? { ...entry, [field]: value } : entry));
    setFormData((prevData) => ({
      ...prevData,
      credit_entries: updatedCreditEntries,
    }));
  };

  // Render debit and credit entries
  const renderDebitEntries = () => {
    // console.log(formData?.debit_entries,getDebitOptions())
    return formData?.debit_entries?.map((entry, index) => (
      <div key={index} className="row mt-2">
        <div className="col-md-3">
          <Select
            options={getDebitOptions()}
            placeholder="--Select Ledger--"
            onChange={(option) => handleDebitEdit(index, 'debit_ledger_id', option.value)}
            value={ getDebitOptions()?.find((opt) => opt.value === entry.debit_ledger_id)} // Reset when null
          />
        </div>
        <div className="col-md-3">
          {/* <p>₹{entry.debit_amount}</p> */}
          <input
            aria-describedby="basic-addon1"
            aria-label="Username"
            class="form-control"
            type="text"
            value={entry.debit_amount}
            // onChange={(e) => handleDebitEdit(index, 'debit_amount', e.target.value)}
            onChange={(e) => {
              const value = e.target.value;
              // Validate that the value is a valid number and not negative
              if (!isNaN(value) && value >= 0) {
                handleDebitEdit(index, 'debit_amount', e.target.value);
              }
            }}
          />
        </div>
        <div className="col-md-3">
          <button className="btn-danger" onClick={() => removeDebitEntry(index)}>
            Remove
          </button>
        </div>
      </div>
    ));
  };

  const renderCreditEntries = () => {
    return formData?.credit_entries?.map((entry, index) => (
      <div key={index} className="row mt-2">
        <div className="col-md-3">
          <Select
             options={getCreditOptions()}
            placeholder="--Select Ledger--"
            onChange={(option) => handleCreditEdit(index, 'credit_ledger_id', option.value)}
            value={ getCreditOptions().find((opt) => opt.value === entry.credit_ledger_id)} // Reset when null
          />
        </div>
        <div className="col-md-3">
          <input className="form-control" type="text" value={entry.credit_amount}
          //  onChange={(e) => handleCreditEdit(index, 'credit_amount', e.target.value)}
           onChange={(e) => {
            const value = e.target.value;
            // Validate that the value is a valid number and not negative
            if (!isNaN(value) && value >= 0) {
              handleCreditEdit(index, 'credit_amount', e.target.value);
            }
          }}
           />
        </div>
        <div className="col-md-3">
          <button className=" btn-danger" onClick={() => removeCreditEntry(index)}>
            Remove
          </button>
        </div>
      </div>
    ));
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    })
      .format(amount)
      .replace('₹', '₹ '); // Adds a space after the rupee sign for better readability
  };

  const totalDebitAmount = formData.debit_entries.reduce((total, entry) => total + (parseFloat(entry.debit_amount) || 0), 0);
  const totalCreditAmount = formData.credit_entries.reduce((total, entry) => total + (parseFloat(entry.credit_amount) || 0), 0);

  const validateForm = () => {
    const newErrors = {};

    // Check if voucher name is provided
    if (!formData.voucher_name) {
      newErrors.voucher_name = 'Payment Name is required.';
    }

    // Check if voucher date is provided
    if (!formData.voucher_date) {
      newErrors.voucher_date = 'Payment date is required.';
    }

    // Check if there is at least one debit entry
    if (!formData.debit_entries || formData.debit_entries.length === 0) {
      toast.error('At least one debit entry is required.');
      newErrors.debit_entries = 'At least one debit entry is required.';
    }

    // Check if there is at least one credit entry
    if (!formData.credit_entries || formData.credit_entries.length === 0) {
      toast.error('At least one credit entry is required.');
      newErrors.credit_entries = 'At least one credit entry is required.';
    }

    // Check if debit and credit amounts are equal
    if (totalDebitAmount !== totalCreditAmount) {
      console.log('hii');
      toast.error('Debit and Credit entries does not match.');
      newErrors.mismatch = 'Total Debit and Credit amounts must be equal.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    console.log("formData",formData)
    // setIsLoading(true);

    try {
      // Use await to wait for the dispatch to finish
      await dispatch(AddPaymentReceiptContra(formData)).unwrap();
      setIsLoading(false);
      navigate('/payment-receipt-contra/list');
    } catch (error) {
      // Handle the error if the API call fails
      setIsLoading(false);
      console.log(error.message);
    }
  };

  // console.log(formData);
  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="row content-body">
        <div className="container">
          <div className="page-header">
            <div>
              <h2 class="main-content-title tx-24 mg-b-5">Payment/Receipt/Contra</h2>

              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Add </a>
                </li>
              </ol>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn ripple btn-default" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card custom-card">
                <div class="card-body">
                  <div class="form-group">
                    {/* <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-6">
                              <label>
                                Select Transaction Type <span class="required">*</span>
                              </label>
                            </div>
                            <div class="col-md-12 d-flex gap-3">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="type" id="paymentRadio" value="Payment" checked={formData.type === 'Payment'} onChange={handleInputChange} />
                                <label class="form-check-label" for="paymentRadio">
                                  Payment
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="type" id="receiptRadio" value="Receipt" checked={formData.type === 'Receipt'} onChange={handleInputChange} />
                                <label class="form-check-label" for="receiptRadio">
                                  Receipt
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="type" id="contraRadio" value="Contra" checked={formData.type === 'Contra'} onChange={handleInputChange} />
                                <label class="form-check-label" for="contraRadio">
                                  Contra
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br> */}
                    <div class="row">
                      <div class="col-md-3">
                        <label>
                        {type} Date <span class="required">*</span>
                        </label>
                        <input name="voucher_date" type="date" class="form-control" value={formData.voucher_date} onChange={handleInputChange} />
                      </div>
                      <div class="col-md-3">
                        <label>
                          Remark <span class="required">*</span>
                        </label>
                        <input
                          name="voucher_name"
                          aria-describedby="basic-addon1"
                          required="required"
                          aria-label="Username"
                          class="form-control"
                          type="text"
                          value={formData.voucher_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <hr />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-3">
                        <label>
                          Debit Account <span class="required">*</span>
                        </label>
                        <Select
                          options={getDebitOptions()}
                          placeholder="--Select Ledger--"
                          onChange={(option) => handleDebitChange('debit_ledger_id', option.value)}
                          value={debitEntry.debit_ledger_id ? getDebitOptions().find((opt) => opt.id === debitEntry.debit_ledger_id) : null} 
                        />
                      </div>
                      <div class="col-md-3">
                        <label>
                          Amount <span class="required">*</span>
                        </label>
                        <input
                          aria-describedby="basic-addon1"
                          aria-label="Username"
                          class="form-control"
                          type="text"
                          value={debitEntry.debit_amount}
                          // onChange={(e) => handleDebitChange('debit_amount', e.target.value)}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Validate that the value is a valid number and not negative
                            if (!isNaN(value) && value >= 0) {
                              handleDebitChange('debit_amount', value);
                            }
                          }}
                        />
                      </div>

                      <div class="col-md-3">
                        <a href="#" class="mt-3 pt-4" style={{ display: 'inline-block' }} onClick={addDebitEntry}>
                          Add More
                        </a>
                      </div>
                    </div>

                    {renderDebitEntries()}
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-3">
                        <label>
                          Credit Account <span class="required">*</span>
                        </label>
                        <Select
                          options={getCreditOptions()}
                          placeholder="--Select Ledger--"
                          onChange={(option) => handleCreditChange('credit_ledger_id', option.value)}
                          value={creditEntry.credit_ledger_id ? getCreditOptions().find((opt) => opt.id === creditEntry.credit_ledger_id) : null} // Reset when null
                        />
                      </div>
                      <div class="col-md-3">
                        <label>
                          Amount <span class="required">*</span>
                        </label>
                        <input
                          aria-describedby="basic-addon1"
                          aria-label="Username"
                          class="form-control"
                          type="text"
                          value={creditEntry.credit_amount}
                          // onChange={(e) => handleCreditChange('credit_amount', e.target.value)}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Validate that the value is a valid number and not negative
                            if (!isNaN(value) && value >= 0) {
                              handleCreditChange('credit_amount', value);
                            }
                          }}
                        />
                      </div>

                      <div class="col-md-3">
                        <a href="#" class="mt-3 pt-4" style={{ display: 'inline-block' }} onClick={addCreditEntry}>
                          Add More
                        </a>
                      </div>
                    </div>
                    {renderCreditEntries()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-md-6">
              <div class="card custom-card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 d-flex">
                      <p className="fw-bolder fs-6" style={{ fontSize: '1rem', fontWeight: '700' }}>
                        Total Debit Amount:
                      </p>
                      <span style={{ fontSize: '1rem', marginLeft: '0.5rem' }}>{formatCurrency(totalDebitAmount)}</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 d-flex">
                      <p className="fw-bolder fs-6" style={{ fontSize: '1rem', fontWeight: '700' }}>
                        Total Credit Amount :{' '}
                      </p>{' '}
                      <span style={{ fontSize: '1rem', marginLeft: '0.5rem' }}>{formatCurrency(totalCreditAmount)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default PaymentReceiptContraAdd;
