import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetByInvoiceslist, invoicesDelete } from '../store/slices/sale';
import { ListParties } from '../store/slices/parties';
import Loader from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Table from '../common/Table';
import AdminLayout from './AdminLayout';
import { API_BASE_URL, API_PATHS } from '../utils/constants/api.constants';
import { FaFilePdf } from 'react-icons/fa6';

const InvoiceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [invoicelist, setInvoicelist] = useState([]);
  const [listParties, setListParties] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedParty, setSelectedParty] = useState(null); // For ledger_id
  const [columns, setColumns] = useState([
    { header: 'Invoice Number', field: 'invoice_combined' },
    { header: 'Date', field: 'invoice_date' },
    { header: 'Party', field: 'party_name' },
    { header: 'State', field: 'billing_state' },
    { header: 'Amount', field: 'grand_total' },
    { header: 'Payment Status', field: '' },
    {
      header: 'Actions',
      field: 'actions',
      isAction: true,
      actionButtons: [
        { name: 'Edit', className: 'btn-default' },
        { name: 'Delete', className: 'btn-cancel' },
        {
          className: 'btn-danger',
          icon: <FaFilePdf style={{ fontSize: '15px' }} />,
        },
      ],
    },
  ]);

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const partyOptions = listParties.map((party) => ({
    value: party.id,
    label: party.ledger,
  }));

  const fetchParties = async () => {
    try {
      const data = await dispatch(ListParties({ profile_id: id })).unwrap();
      setListParties(data?.data);
    } catch (error) {
      console.log('Error fetching parties:', error.message);
    }
  };

  const fetchInvoices = async () => {
    setIsLoading(true);
    const params = {
      profile_id: id,
      from_date: fromDate,
      to_date: toDate,
      ledger_id: selectedParty?.value || '',
    };
    // console.log(params)
    try {
      const data = await dispatch(GetByInvoiceslist(params)).unwrap();
      setIsLoading(false);
      setInvoicelist(data?.data);
    } catch (error) {
      setIsLoading(false);
      setInvoicelist([]);
      console.log('Error fetching invoices:', error.message);
    }
  };

  useEffect(() => {
    fetchParties();
    fetchInvoices();
  }, [dispatch]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (item) => {
    navigate(`/Updateaddinvoice/${item.id ? item.id : null}`);
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this invoice?');
    if (confirmDelete) {
      setIsLoading(true);
      dispatch(invoicesDelete({ id: item.id }))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          fetchInvoices(); // Refresh the invoice list
        })
        .catch(({ message }) => {
          setIsLoading(false);
          console.log(message);
        });
    }
  };

  const handlePDF = (item) => {
    const data = `${API_BASE_URL}${API_PATHS.invoicespdf}/${item?.id}`;
    navigate('/Pdf-Viewer', { state: { data } });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchInvoices();
  };

  const filtereditemList = invoicelist?.filter(
    (party) =>
      party?.invoice_number?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.invoice_date?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.billing_state?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.party_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.grand_total?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="row content-body">
        <div className="container-fluid">
          <div className="page-header">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Invoices</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Sale</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Invoice List
                </li>
              </ol>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn ripple btn-default" onClick={() => navigate('/invoice')}>
                Add New Invoice
              </button>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-12">
              <div class="card custom-card">
                <div class="card-body">
                  <form onSubmit={handleFilterSubmit} className="row mb-4">
                    <div className="col-md-3">
                      <label>Customer</label>
                      <Select options={partyOptions} placeholder="--Select Customer--" value={selectedParty} onChange={setSelectedParty} />
                    </div>
                    <div className="col-md-3">
                      <label>From Date</label>
                      <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                      <label>To Date</label>
                      <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>
                    <div className="col-md-3 mt-2">
                      <button type="submit" className="btn btn-default mt-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                columns={columns}
                data={filtereditemList}
                tableRef={tableRef}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                totalCount={filtereditemList?.length}
                onPageChange={handlePageChange}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handlePDF={handlePDF}
                handleSearchChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default InvoiceList;
