import React, { useState, useEffect ,useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { JournalVoucherList, JournalvoucherDelete} from '../store/slices/journal';
import { ListParties } from '../store/slices/parties';
import Loader from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Table from '../common/Table';
import AdminLayout from './AdminLayout';

const JournalList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef=useRef(null)

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [VoucherList, setVoucherList] = useState([]);
    const [listParties, setListParties] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedParty, setSelectedParty] = useState(null);
  const [columns, setcolumns] = useState([
    { header: 'Id', field: 'id' },
    { header: 'Voucher Name', field: 'voucher_name' },
    { header: 'Voucher Date', field: 'voucher_date' },
    { header: 'Debit Amount', field: 'debit_amount' },
    { header: 'Credit Amount', field: 'credit_amount' },
    { 
      header: 'Actions', 
      field: 'actions', 
      isAction: true, 
      actionButtons: [
        { name: 'Edit', className: 'btn-default' }, 
        { name: 'Delete', className: 'btn-cancel' }
      ]
    }
  ]);
 

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const partyOptions = listParties.map((party) => ({
    value: party.id,
    label: party.ledger,
  }));


    const fetchParties = async () => {
      try {
        const data = await dispatch(ListParties({ profile_id: id })).unwrap();
        setListParties(data?.data);
      } catch (error) {
        console.log('Error fetching parties:', error.message);
      }
    };
  
    const fetchJournalVoucherList = async () => {
      setIsLoading(true);
      const params = {
        profile_id: id,
        from_date: fromDate,
        to_date: toDate,
        ledger_id: selectedParty?.value || '',
      };
      // console.log(params)
      try {
        const data = await dispatch(JournalVoucherList(params)).unwrap();
        setIsLoading(false);
        setVoucherList(data?.data);
      } catch (error) {
        setIsLoading(false);
        setVoucherList([]);
        console.log('Error fetching invoices:', error.message);
      }
    };
  
    useEffect(() => {
      fetchParties();
      fetchJournalVoucherList();
    }, [dispatch]);


    const handleFilterSubmit = (e) => {
      e.preventDefault();
      fetchJournalVoucherList();
    };
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (item) => {
     navigate(`/editjournalvoucher/edit/${item.id ? item.id : null}`)
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Journal Voucher?");
    if (confirmDelete) {
          setIsLoading(true);
           dispatch(JournalvoucherDelete({ voucher_id: item.id,profile_id:id}))
          .unwrap()
          .then((data) => {
              setIsLoading(false);
              hindleReturn();
          })
          .catch(({ message }) => {
            setIsLoading(false);
            console.log(message);
          });
    } else {
      console.log("Deletion canceled");
    }
  };

  const hindleReturn = () => {
    setIsLoading(true);
    dispatch(JournalVoucherList({ profile_id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setVoucherList(data?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  };




  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const search = searchQuery?.toLowerCase() || '';
  // Filter data based on search query
  const filteredParties = VoucherList?.filter(party => 
    party?.voucher_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
    party?.voucher_date?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
    String(party?.debit_amount || '').toLowerCase().includes(search)||
    party?.credit_amount?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  return (
    <AdminLayout>
        {isLoading && <Loader />}
          <div className="row content-body">
            <div className="container-fluid">
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Journal Voucher</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Journal Voucher</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Journal Voucher List
                    </li>
                  </ol>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn ripple btn-default" onClick={() => navigate('/addjournal')}>
                  Add Journal Voucher
                  </button>
                </div>
              </div>


              <div class="row mb-4">
            <div class="col-md-12">
              <div class="card custom-card">
                <div class="card-body">
                  <form onSubmit={handleFilterSubmit} className="row mb-4">
                    <div className="col-md-3">
                      <label>From Date</label>
                      <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                      <label>To Date</label>
                      <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>
                    <div className="col-md-3 mt-2">
                      <button type="submit" className="btn btn-default mt-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
              <div className="row">
                <div className="col-md-12"> 
                 <Table
                    columns={columns}
                    data={filteredParties} 
                    tableRef={tableRef}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    currentPage={currentPage}
                    totalCount={filteredParties?.length} 
                    onPageChange={handlePageChange}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleSearchChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
  );
};

export default JournalList;
