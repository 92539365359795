import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetPlStatement } from '../../store/slices/reports';
import Loader from '../../common/Loader';
import AdminLayout from '../AdminLayout';
import moment from 'moment';
import {API_BASE_URL, API_PATHS } from "../../utils/constants/api.constants";

const BalanceSheet = () => {
  const dispatch = useDispatch();
   const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [profitLoss, setProfitLoss] = useState([]);

  // Get today's date
  const today = new Date();

  // Financial year calculation
  const getFinancialYearStartDate = () => {
    const currentYear = today.getMonth() >= 3 ? today.getFullYear() : today.getFullYear() - 1;
    return `${currentYear}-04-01`;
  };

  const getTodayFormatteddate = () => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return today.toLocaleDateString('en-GB', options); // e.g., 31-October-2024
  };

  const getTodayFormattedDate = () => {
    return today.toISOString().split('T')[0]; // Returns 'YYYY-MM-DD'
  };

  // Load dates from local storage or set default dates
  const loadSavedDates = () => {
    const savedFromDate = localStorage.getItem('profitLossFromDate');
    const savedToDate = localStorage.getItem('profitLossToDate');
    return {
      fromDate: savedFromDate || getFinancialYearStartDate(),
      toDate: savedToDate || getTodayFormattedDate(),
    };
  };

  const { fromDate: initialFromDate, toDate: initialToDate } = loadSavedDates();

  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);

  const user = JSON.parse(localStorage.getItem('user'));
  const Name = user?.data?.company_name;
  const profile_id = user?.data?.id;

  useEffect(() => {
    handleProfitLoss();
  }, []);

  const handleProfitLoss = async () => {
    const newItem = {
      profile_id: profile_id,
      from_date: fromDate, // Ensure from_date is sent
      to_date: toDate, // Ensure to_date is sent
    };

    setIsLoading(true);

    try {
      const data = await dispatch(GetPlStatement(newItem)).unwrap();
      console.log(data?.data);
      setProfitLoss(data?.data || []); // Update profitLoss with the response
    } catch (error) {
      console.error('Error fetching profit/loss data:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);

    // Save the selected dates to local storage with unique keys
    localStorage.setItem('profitLossFromDate', startDate);
    localStorage.setItem('profitLossToDate', endDate);
  };

  const resetDates = async () => {
    const defaultFromDate = getFinancialYearStartDate();
    const defaultToDate = getTodayFormattedDate();

    // Update state
    setFromDate(defaultFromDate);
    setToDate(defaultToDate);

    // Clear local storage with unique keys
    localStorage.removeItem('profitLossFromDate');
    localStorage.removeItem('profitLossToDate');

    // Call handleProfitLoss with updated values directly
    const newItem = {
      profile_id: profile_id,
      from_date: defaultFromDate, // Use updated dates here
      to_date: defaultToDate, // Use updated dates here
    };

    setIsLoading(true);

    try {
      const data = await dispatch(GetPlStatement(newItem)).unwrap();

      setProfitLoss(data?.data || []); // Update Profit/Loss with the response
      console.log(data?.data);
    } catch (error) {
      console.error('Error fetching profit/loss data:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDateRange(startDate, endDate) {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };

    const formattedStartDate = new Date(startDate).toLocaleDateString('en-GB', options);
    const formattedEndDate = new Date(endDate).toLocaleDateString('en-GB', options);

    return `${formattedStartDate} to ${formattedEndDate}`;
  }

      const handlePDF = (item) => {
        const data=`${API_BASE_URL}${API_PATHS.pdfbalancesheet}/${toDate}/${fromDate}/${profile_id}`
          navigate('/Pdf-Viewer', { state: { data } });
      };
  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="row content-body">
        <div className="container-fluid">
          <div className="page-header">
            <div>
              <h2 class="main-content-title tx-24 mg-b-5">Balance Sheet</h2>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Reports</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Balance Sheet
                </li>
              </ol>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="card custom-card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3 form-inline">
                          <div class="form-group">
                            <label class="">From Date</label>
                            <input class="form-control" type="date" value={fromDate} onChange={(e) => handleDateChange(e.target.value, toDate)} />
                          </div>
                        </div>
                        <div class="col-md-3 form-inline">
                          <div class="form-group">
                            <label class="">To Date</label>
                            <input class="form-control" type="date" value={toDate} onChange={(e) => handleDateChange(fromDate, e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-5 form-inline">
                          <div class="form-group">
                            <button type="submit" class="btn btn-default" onClick={() => handleProfitLoss()}>
                              Submit
                            </button>
                          </div>
                          <div class="form-group">
                            <button type="button" className="btn btn-default" onClick={resetDates}>
                              Reset Date
                            </button>
                          </div>
                          <div class="form-group">
                       <button type="button" className="btn btn-default" onClick={handlePDF}>
                       Balance Sheet PDF
                        </button>
                         </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      {/* Trial Balance Table */}
                      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <h3 style={{ fontWeight: '700' }} className="text-capitalize">
                          {Name}
                        </h3>
                        <h4 style={{ fontWeight: '700' }}>Balance Sheet</h4>
                        <p>{formatDateRange(fromDate, toDate)}</p>
                      </div>

                      <table className="table table-borderless" style={{ width: '100%', fontSize: '12px' }}>
                        <tbody>
                          <tr>
                            <td style={{ border: '1px solid' }}>
                              <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">Liabilities</span>
                                <span>as to {moment(toDate).format('DD-MM-YYYY')}</span>
                              </div>
                            </td>
                            <td style={{ border: '1px solid' }}>
                              <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">Assets</span>
                                <span>as at {moment(toDate).format('DD-MM-YYYY')}</span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ border: '1px solid', width: '50%' }}>
                              <div className="mb-2">
                                {Object.entries(profitLoss?.liabilities || {})?.map(([group, liabilities], groupIndex) => {
                                  // Calculate total for the current group
                                  const groupTotal = liabilities.reduce((sum, liability) => {
                                    const amount = parseFloat(liability.amount.replace(/,/g, '') || 0);
                                    return sum + amount;
                                  }, 0);

                                  return (
                                    <div key={groupIndex} className="mb-2">
                                      <div className="d-flex justify-content-between font-weight-bold"  >
                                        <b>{group}</b>
                                        <span style={{textAlign:"right" }} >₹{groupTotal.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span>
                                      </div>
                                     
                                    </div>
                                  );
                                })}
                              </div>
                            </td>

                            <td style={{ border: '1px solid' }}>
                              <div className="mb-2">
                                {Object.entries(profitLoss?.assets || {})?.map(([group, expenses], groupIndex) => {
                                  // Calculate total for the current group
                                  const groupTotal = expenses.reduce((sum, expense) => {
                                    const amount = parseFloat(expense.amount.replace(/,/g, '') || 0);
                                    return sum + amount;
                                  }, 0);

                                  return (
                                    <div key={groupIndex} className="mb-2">
                                      <div className="d-flex justify-content-between font-weight-bold">
                                        <b>{group}</b>
                                        <span style={{textAlign:"right" }}>₹{groupTotal.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span>
                                      </div>
                                    
                                    </div>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ border: '1px solid' }}>
                              <div className="d-flex justify-content-between">
                                <span>Total</span>
                                <span className="font-weight-bold">₹{parseFloat(profitLoss?.total_liabilities?.replace(/,/g, '') || 0).toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span>
                              </div>
                            </td>
                            <td style={{ border: '1px solid' }}>
                              <div className="d-flex justify-content-between">
                                <span>Total</span>
                                <span className="font-weight-bold">₹{parseFloat(profitLoss?.total_assets?.replace(/,/g, '') || 0).toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default BalanceSheet;
