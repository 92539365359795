import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import JournalService from "../../services/journal.service"

export const AddJournalVoucher = createAsyncThunk(
    "/post/addjournalvoucher",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.AddJournalVoucher(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const JournalVoucherList = createAsyncThunk(
    "/post/journalvoucherlist",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.JournalVoucherlist(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const UpdateJournalVoucher = createAsyncThunk(
    "/post/updatejournalvoucher",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.UpdateJournalVoucher(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const Getdetailjournalvoucher = createAsyncThunk(
    "/post/getdetailjournalvoucher",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.Getdetailjournalvoucher(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const JournalvoucherDelete = createAsyncThunk(
    "/post/journalvoucherDelete",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.JournalvoucherDelete(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const AddPaymentReceiptContra = createAsyncThunk(
    "/post/addpaymentreceiptcontra",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.AddPaymentReceiptContra(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const listPaymentReceiptContra = createAsyncThunk(
    "/post/listpaymentreceiptcontra",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.listPaymentReceiptContra(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const GetdetailPaymentReceiptContra = createAsyncThunk(
    "/post/getdetailpaymentreceiptcontra",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.GetdetailPaymentReceiptContra(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const UpdatePaymentReceiptContra = createAsyncThunk(
    "/post/Updatepaymentreceiptcontra",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.UpdatePaymentReceiptContra(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const GetCashLedgerId = createAsyncThunk(
    "/post/GetCashLedgerId",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.GetCashLedgerId(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const PaymentContraDelete = createAsyncThunk(
    "/post/PaymentContraDelete",
    async (item,thunkAPI) => {
        try {
            const data = await JournalService.PaymentContraDelete(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);