import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AuditLogDetails = ({ show, handleClose, oldValues, newValues }) => {
  // Parse old and new values
  const oldData = oldValues && oldValues !== "[]" ? JSON.parse(oldValues) : {};
  const newData = newValues && newValues !== "[]" ? JSON.parse(newValues) : {};

  // Recursive function to render nested JSON in a readable format
  const renderNestedData = (data, depth = 0) => {
    if (typeof data !== 'object' || data === null) {
      return <span>{data}</span>; // Render primitive values directly
    }

    return (
      <ul style={{ listStyleType: depth === 0 ? 'none' : 'circle', paddingLeft: depth === 0 ? '0' : '20px' }}>
        {Object.entries(data).map(([key, value]) => (
          <li key={key} style={{ marginBottom: '10px' }}>
            <strong>{key}:</strong>{' '}
            {typeof value === 'object' && value !== null ? (
              renderNestedData(value, depth + 1) // Recurse for nested objects/arrays
            ) : (
              <span>{value}</span> // Render primitive values
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>Audit Log Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {/* Old Data Section */}
          <div className="col-md-6">
            <h5 className="font-weight-bold">Old Data</h5>
            <div>
              {Object.keys(oldData).length > 0 ? (
                renderNestedData(oldData)
              ) : (
                <p>No old data available</p>
              )}
            </div>
          </div>
          {/* New Data Section */}
          <div className="col-md-6">
            <h5 className="font-weight-bold">New Data</h5>
            <div>
              {Object.keys(newData).length > 0 ? (
                renderNestedData(newData)
              ) : (
                <p>No new data available</p>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AuditLogDetails;
