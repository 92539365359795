import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminLayout from '../component/AdminLayout';

const PdfViewer = () => {
  const location = useLocation();
  const { data } = location.state || {}; // Extract the data passed during navigation
// console.log("datadata",data)
  if (!data) {
    return <div>No PDF data available. Please provide valid data to view the PDF.</div>;
  }

 

  return (
    <AdminLayout>
    <div>
      <iframe
        src={data}
        width="100%"
        height="800px"
        title="PDF Viewer"
        style={{ border: 'none' }}
      ></iframe>
    </div>
    </AdminLayout>
  );
};

export default PdfViewer;
