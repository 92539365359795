import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { UpdateLoginPassword } from '../store/slices/auth';
import { useDispatch } from 'react-redux';
import logo from '../img/logos/kisaankhatalogo.png';
import Loader from '../common/Loader';
import { VscEye } from 'react-icons/vsc';

const ResetPassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alertNewPassword, setAlertNewPassword] = useState('');
  const [alertConfirmPassword, setAlertConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const validatePassword = () => {
    let isValid = true;

    if (newPassword.length < 6) {
      setAlertNewPassword('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setAlertNewPassword('');
    }

    if (newPassword !== confirmPassword) {
      setAlertConfirmPassword('Passwords do not match.');
      isValid = false;
    } else {
      setAlertConfirmPassword('');
    }

    return isValid;
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (validatePassword()) {
      const payloaddata={
        id:id,
        profile_password:newPassword
      }
      try {
        setIsLoading(true);
        const data = await dispatch(UpdateLoginPassword(payloaddata)).unwrap();
        setIsLoading(false);
        toast.success('Password updated successfully');
        navigate('/'); // Navigate to login page after success
      } catch (error) {
        setIsLoading(false);
        toast.error('Failed to update password. Please try again.');
      }
    }
  };

  return (
    <div>
      {isLoading && <Loader isLogin={true} />}
      <section className="login-bg-image">
        <div className="bg-overlay-orange"></div>
        <div className="card-section">
          <div className="d-flex justify-content-around align-items-center">
            <img src={logo} alt="travel-logo" className="div-logo" />
            <div className="h-logn-line" />

            <div className="login-form-2">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="logo" className="logo-form-2" />
              </div>
              <h2 className="text-start mb-4" style={{ color: 'black', fontWeight: '500' }}>
                Reset Password
              </h2>
              <form onSubmit={handleUpdatePassword}>
                {/* New Password Input */}
                <div className="position-r">
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="form-control text-black-ph"
                    placeholder="New Password"
                    style={{ height: '50px' }}
                  />
                  <VscEye className="eye_icon_login" onClick={togglePassword} />
                </div>
                {alertNewPassword && <p className="alert-message">{alertNewPassword}</p>}

                {/* Confirm Password Input */}
                <div className="position-r mt-4">
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="form-control text-black-ph"
                    placeholder="Confirm Password"
                    style={{ height: '50px' }}
                  />
                  <VscEye className="eye_icon_login" onClick={togglePassword} />
                </div>
                {alertConfirmPassword && <p className="alert-message">{alertConfirmPassword}</p>}

                {/* Submit Button */}
                <div className="col-md-12 d-flex justify-content-center mt-4">
                  <button type="submit" className="btn btn-warning login-btn col-white">
                    Update Password
                  </button>
                </div>
                <hr />
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
