import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetClientsDetail } from '../store/slices/auth';
import { UpdateCashOpeningBalance, LedgerEntires } from '../store/slices/bankbook';
import Table from '../common/Table';
import { toast } from 'react-hot-toast';
import Loader from '../common/Loader';
import AdminLayout from './AdminLayout';

const CashBook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;

  const [currentDate, setCurrentDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [item, setItem] = useState({
    ledger_id: 1,
    profile_id: id,
  });

  const [columns, setcolumns] = useState([
    { header: 'Type', field: 'type' },
    { header: 'Description', field: 'description', isMultiline: true },
    { header: 'Date', field: 'ledger_date' },
    { header: 'Dr', field: 'dr', isDrCr: true, isDr: true },
    { header: 'Cr', field: 'cr', isDrCr: true, isDr: false },
    { header: 'Closing Balance', field: 'balance' },
  ]);

  const [ledgerEntires, setLedgerEntires] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchQuery, setSearchQuery] = useState('');

  const [formData, setFormData] = useState({
    id: id,
    cash_opening_balance: '',
    cash_opening_balance_date: '',
  });

  const [errors, setErrors] = useState({});

  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  useEffect(() => {
    fetchClientDetails();
    // Get the current date
    handleLedgerClick();
    setCurrentDate(formattedDate);
    // Set date and date_as_of to the current date in formData
    setFormData((prevData) => ({
      ...prevData,
      cash_opening_balance_date: formattedDate,
    }));
  }, []);

  const handleLedgerClick = async (e) => {
    if (e) e.preventDefault(); // Prevent default only if `e` is present

    const newItem = {
      ...item,
      ledger_id: 1,
    };
    setItem(newItem);

    const requestId = Date.now();
    const currentRequestId = requestId;
    setIsLoading(true);

    try {
      const data = await dispatch(LedgerEntires(newItem)).unwrap();

      if (currentRequestId === requestId) {
        setLedgerEntires(data?.data?.entries || []);
      }
    } catch (error) {
      toast.error('No Record Found!!');
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchClientDetails = async () => {
    try {
      const data = await dispatch(GetClientsDetail({ id: id })).unwrap();
      setFormData((prevData) => ({
        ...prevData,
        cash_opening_balance: data?.user?.data?.cash_opening_balance || 0,
        cash_opening_balance_date: data?.user?.data?.cash_opening_balance_date || formattedDate,
      }));
    } catch (error) {
      console.log('Error fetching ClientDetails:', error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'cash_opening_balance') {
      // Allow only numbers with 2 decimal places
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDiscard = () => {
    setFormData({
      id: id,
      cash_opening_balance: '',
      cash_opening_balance_date: currentDate,
    });
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.cash_opening_balance || parseFloat(formData.cash_opening_balance) <= 0) newErrors.cash_opening_balance = 'Please enter a valid amount greater than 0.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // console.log("formData",formData,currentDate)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      dispatch(UpdateCashOpeningBalance(formData))
        .unwrap()
        .then((data) => {
          setIsLoading(false);
          setFormData({
            id: id,
            cash_opening_balance: '',
            cash_opening_balance_date: currentDate,
          });
          fetchClientDetails();
          navigate('/');
        })
        .catch(({ message }) => {
          setIsLoading(false);
          console.log(message);
        });
    }
  };

  const filteredledgerEntires = (ledgerEntires || [])?.filter((party) => {
    const search = searchQuery?.toLowerCase() || '';
    return (
      party?.type?.toLowerCase()?.includes(search) ||
      party?.description?.toLowerCase()?.includes(search) ||
      party?.added_on?.toLowerCase()?.includes(search) ||
      String(party?.dr || '')
        .toLowerCase()
        .includes(search) || // Safely convert to string
      String(party?.cr || '')
        .toLowerCase()
        .includes(search) || // Safely convert to string
      String(party?.balance || '')
        .toLowerCase()
        .includes(search) // Safely convert to string
    );
  });

  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="row content-body">
        <div className="container">
          <div className="page-header">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Cash Book</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Cash Book</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add CashBook Opening Balance
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          Opening Balance <span className="required">*</span>
                        </label>
                        <input name="cash_opening_balance" type="text" className="form-control" onChange={handleInputChange} value={Number(formData?.cash_opening_balance) || 0.0} />
                        {errors?.cash_opening_balance && <span className="alert-message">{errors?.cash_opening_balance}</span>}
                      </div>
                      <div className="col-md-6">
                        <label>
                          Date <span className="required">*</span>
                        </label>
                        <input name="cash_opening_balance_date" type="date" className="form-control" onChange={handleInputChange} value={formData?.cash_opening_balance_date || currentDate} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <button type="button" className="btn btn-default" onClick={handleSubmit}>
                          Submit
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-cancel" onClick={handleDiscard}>
                          Cancel
                        </button>
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-md-12">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div class="row mt-3">
                        <div class="col-md-12">
                          <Table
                            columns={columns}
                            data={filteredledgerEntires}
                            tableRef={tableRef}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            currentPage={currentPage}
                            totalCount={filteredledgerEntires?.length}
                            onPageChange={handlePageChange}
                            handleSearchChange={handleSearchChange}
                            handleSubmit={handleLedgerClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CashBook;
