import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {toast } from 'react-hot-toast';
import { Getunits } from '../store/slices/settings';
import { Listitems } from '../store/slices/items';
import { Getsingledetail } from '../store/slices/sale';
import ProductSelector from '../common/ProductSelector';
import ItemRow from '../common/ItemRow';

const UpdateInvoiceSecond = ({ onChildDataChange, onSubmit,data }) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;
  //  console.log("data",data)
  const [state, setState] = useState({
    units: [],
    itemList: [],
    singleDetail: {},
    selectedProduct: null,
    quantity: 1,
    unit_id: null,
    discount: 0,
    price: 0,
    discount_type: 'Fixed',
    shippingCost:0,
    addedItems: [],
    tax: 0,
    price_tax_type: 'Excluding Tax',
    subtotal: 0,
    taxAmounts: {},
    hsn: null,
    shippingGst: 0,
  });

  const fetchUnits = useCallback(async () => {
    try {
      const data = await dispatch(Getunits()).unwrap();
      setState((prevState) => ({ ...prevState, units: data.data }));
    } catch (error) {
      console.log(error.message);
    }
  }, [dispatch]);

  const fetchItemList = useCallback(async () => {
    try {
      const data = await dispatch(Listitems({ profile_id: id })).unwrap();
      setState((prevState) => ({ ...prevState, itemList: data?.data }));
    } catch (error) {
      console.error(error.message);
    }
  }, [dispatch, id]);

  useEffect(() => {
    fetchUnits();
    fetchItemList();
  }, [fetchUnits, fetchItemList]);


  useEffect(() => {
    // Initialize addedItems from the passed data
    if (data?.items) {
      const initializedItems = data.items.map((item) => ({
        id:item.id,
        hsn: item.hsn,
        item_id: item.item_id,
        quantity: item.quantity,
        unit_id: item.unit_id,
        unit_name: item.unit_name, 
        price: item.price,
        price_tax_type: item.price_tax_type,
        tax: item.tax,
        tax_type: item.tax_type,
        discount: item.discount,
        discount_type: item.discount_type,
        tax_amount: item.tax_amount,
        sub_total: item.sub_total,
        total_amount: item.total_amount,
      }));
  
      // Calculate initial taxAmounts based on data?.items
      const initialTaxAmounts = initializedItems.reduce((acc, item) => {
        const taxRate = parseFloat(item.tax) || 0;
        const taxAmount = parseFloat(item.tax_amount) || 0;
  
        if (taxRate > 0) {
          if (acc[taxRate]) {
            acc[taxRate] += taxAmount;
          } else {
            acc[taxRate] = taxAmount;
          }
        }
        return acc;
      }, {});
  
      // Check if shipping cost exists and calculate shipping GST (12%)
      const shippingCost = parseFloat(state?.shippingCost) || 0;
      if (shippingCost > 0) {
        const shippingTaxRate = 12; // Fixed 12% GST for shipping
        const shippingGst = parseFloat(((shippingCost * shippingTaxRate) / 100).toFixed(2));
        setState((prevState) => ({
          ...prevState,
          shippingGst:shippingGst
        }));
        // Add shipping GST to initialTaxAmounts
        if (initialTaxAmounts[shippingTaxRate]) {
          initialTaxAmounts[shippingTaxRate] += shippingGst;
        } else {
          initialTaxAmounts[shippingTaxRate] = shippingGst;
        }
      }
      setState((prevState) => ({
        ...prevState,
        addedItems: initializedItems,
        taxAmounts: initialTaxAmounts,
        shippingCost: Number(data?.invoice?.shipping_cost) || 0,

      }));
    }
  }, [data]);


  const handleProductChange = useCallback(
    async (productId) => {
      setState((prevState) => ({
        ...prevState,
        selectedProduct: productId ? productId : null,
      }));

      if (productId) {
        try {
          const data = await dispatch(Getsingledetail({ profile_id: id, item_id: productId })).unwrap();
          setState((prevState) => ({
            ...prevState,
            price: Number(data?.data?.sale_price)?.toFixed(2),
            singleDetail: data?.data,
            unit_id: data?.data?.unit || '',
            tax: data?.data?.tax || 0,
            price_tax_type: data?.data?.sale_price_tax_type || 'Excluding Tax',
            discount_type: data?.data?.discount_type || "Fixed",
            discount:Number(data?.data?.discount).toFixed(2)||0,
          }));
        } catch (error) {
          console.error(error.message);
        }
      } else {
        // Optionally clear product details if no product is selected
        setState((prevState) => ({
          ...prevState,
          singleDetail: {},
          selectedUnit: '',
          tax: 0,
          price_tax_type: 'Excluding Tax',
        }));
      }
    },
    [dispatch, id]
  );

  const handleInputChange = (field, value) => {
    if (field === 'shippingCost') {
      const shippingCost = parseFloat(value) || 0;
      const shippingTaxRate = 12; // Fixed rate for shipping GST
      const shippingGst = parseFloat(((shippingCost * shippingTaxRate) / 100).toFixed(2)); // Calculate GST for shipping
    //  console.log("1",shippingCost,shippingGst)
      // Retrieve the existing shipping GST from the state or set it to 0 if not present
      const existingShippingGst = state.shippingGst || 0;
      // console.log("2",existingShippingGst)
      // Copy the existing taxAmounts state
      const updatedTaxAmounts = { ...state.taxAmounts };
      console.log("3",updatedTaxAmounts)
      // Calculate the difference between the new shipping GST and the existing one
      const difference = shippingGst - existingShippingGst;
      // console.log("3",updatedTaxAmounts)
      // Adjust the tax amount for 12% GST based on the shipping GST difference
      if (updatedTaxAmounts[shippingTaxRate]) {
        updatedTaxAmounts[shippingTaxRate] += difference;
      } else {
        updatedTaxAmounts[shippingTaxRate] = shippingGst; // Initialize shipping GST for the 12% rate if not present
      }
      // console.log("4",updatedTaxAmounts,shippingGst,difference)
  
      // Remove the tax rate if the amount becomes zero
      if (updatedTaxAmounts[shippingTaxRate] <= 0) {
        delete updatedTaxAmounts[shippingTaxRate];
      }
      console.log("5",updatedTaxAmounts)
      // Update the state with the new shipping cost and updated taxAmounts
      setState((prevState) => ({
        ...prevState,
        shippingCost,
        shippingGst, // Store the current shipping GST for future use
        taxAmounts: updatedTaxAmounts, // Update the tax amounts including shipping GST
      }));
    } else {
      // For other fields, update state normally
      setState((prevState) => ({ ...prevState, [field]: value }));
    }
  };
   
  

   const calculateTotal = useMemo(() => {
     const { quantity, discount, discount_type, tax, price_tax_type } = state;
     let price = Number(state.price) || 0;
     let quantityTotal = price * quantity || 0;
     let taxAmount = 0;
     let totalBeforeTax = quantityTotal;
 
     // Adjust tax calculation
     if (price_tax_type === 'Including Tax') {
       // Calculate pre-tax total from the quantityTotal (adjusted for tax)
       const preTaxTotal = quantityTotal / (1 + tax / 100);
       const discountAmount = discount_type === 'Fixed' ? Math.min(Number(discount), preTaxTotal) : (preTaxTotal * Number(discount)) / 100;
 
       const totalAfterDiscount = preTaxTotal - discountAmount;
       taxAmount = +(totalAfterDiscount * (tax / 100)).toFixed(2);
     } else if (price_tax_type === 'Excluding Tax') {
       const preTaxTotal = quantityTotal;
       taxAmount = +((preTaxTotal - (discount_type === 'Fixed' ? Number(discount) : (preTaxTotal * Number(discount)) / 100)) * (tax / 100)).toFixed(2);
     }
 
     // Apply discount
     let discountAmount = 0;
     if (discount_type === 'Fixed') {
       discountAmount = Math.min(Number(discount), totalBeforeTax);
     } else if (discount_type === 'Percentage') {
       console.log('Percentage', totalBeforeTax, discount);
       discountAmount = +((totalBeforeTax * Number(discount)) / 100).toFixed(2);
       console.log('Percentage..', discountAmount);
     }
 
     const discountedTotal = +(totalBeforeTax - discountAmount).toFixed(2);
     const finalTotal = +(discountedTotal + taxAmount).toFixed(2);
   
     // console.log('Price:', price);
     // console.log('Quantity:', quantity);
     // console.log('Total Before Tax:', totalBeforeTax);
     // console.log('Tax Amount:', taxAmount);
     // console.log('Discount Amount:', discountAmount);
     // console.log('Final Total:', finalTotal);
   
     return {
       finalTotal,
       discountedTotal,
       taxAmount,
       subtotal: discountedTotal,
       discountAmount,
     };
   }, [state]);
 

  const handleAddItem = () => {
    const {selectedProduct, unit_id, singleDetail, price_tax_type, tax, discount, discount_type,price,quantity } = state;

    if (!selectedProduct || quantity <= 0 || Number(price) <= 0) {
      toast.error('Please fill out all fields correctly.');
      return;
    }
    const unitName = state.units.find((unit) => unit.id === unit_id)?.unit || '';

    const newItem = {
      hsn: singleDetail.hsn,
      item_id: state.selectedProduct,
      quantity: state.quantity,
      unit_id: unit_id,
      unit_name: unitName,
      price: state.price,
      price_tax_type: price_tax_type,
      tax: tax,
      tax_type: 'GST',
      tax_amount: calculateTotal.taxAmount.toFixed(2),
      discount: discount,
      discount_type: discount_type,
      sub_total: calculateTotal.subtotal.toFixed(2),
      total_amount: calculateTotal.finalTotal.toFixed(2),
    };

    const recalculatedTax = calculateTotal.taxAmount;

    const newTaxAmounts = { ...state.taxAmounts };

    const currentTaxRate = parseFloat(tax);
    if (currentTaxRate) {
      if (newTaxAmounts[currentTaxRate]) {
        newTaxAmounts[currentTaxRate] += recalculatedTax;
      } else {
        newTaxAmounts[currentTaxRate] = recalculatedTax;
      }
    }

    setState((prevState) => ({
      ...prevState,
      addedItems: [...prevState.addedItems, newItem],
      taxAmounts: newTaxAmounts,
    }));

    clearInputs();
  };

  const clearInputs = () => {
    setState((prevState) => ({
      ...prevState,
      selectedProduct: null,
      quantity: 1,
      discount: 0,
      discount_type: 'Fixed',
      selectedUnit: '',
      tax: 0,
      price_tax_type: 'Excluding Tax',
      singleDetail: {},
      unit_id: '',
      price:0
    }));
  };

  const handleItemChange = useCallback(
    (field, value, index) => {
      // console.log("Field changed:", field, "Value:", value, "Index:", index);
  
      const updatedItems = [...state.addedItems];
      updatedItems[index] = { ...updatedItems[index], [field]: value };
  
      const item = updatedItems[index];
      const itemPrice = parseFloat(item.price) || 0;
      const itemQuantity = parseFloat(item.quantity) || 0;
      const itemTotalWithoutTax = itemPrice * itemQuantity;
  
      // console.log("Item Price:", itemPrice, "Item Quantity:", itemQuantity, "Item Total Without Tax:", itemTotalWithoutTax);
  
      const oldGst = parseFloat(state.addedItems[index]?.tax) || 0;
      const newGst = parseFloat(field === "tax" ? value : item.tax) || 0;
      const discountValue = parseFloat(item.discount) || 0;
      const discountType = item.discount_type;
      const priceTaxType = item.price_tax_type;
  
      // console.log("Old GST:", oldGst, "New GST:", newGst, "Discount Value:", discountValue, "Discount Type:", discountType, "Price Tax Type:", priceTaxType);
  
      let discountedTotal = itemTotalWithoutTax;
      let discountAmount = 0;
  
      const calculateDiscount = (total, discountValue, type) => {
        if (type === "Percentage") return (total * discountValue) / 100;
        if (type === "Fixed") return Math.min(discountValue, total);
        return 0;
      };
  
      const calculateTax = (total, gst, taxIncluded) => {
        if (taxIncluded) return (total * gst) / (100 + gst);
        return (total * gst) / 100;
      };
  
      if (priceTaxType === "Including Tax") {
        discountedTotal = itemTotalWithoutTax / (1 + newGst / 100);
        // console.log("Discounted Total (Including Tax):", discountedTotal);
      }
  
      discountAmount = calculateDiscount(discountedTotal, discountValue, discountType);
      discountedTotal -= discountAmount;
  
      // console.log("Discount Amount:", discountAmount, "Discounted Total After Discount:", discountedTotal);
  
      const newTaxAmount = calculateTax(discountedTotal, newGst, priceTaxType === "Including Tax");
      const finalTotal = priceTaxType === "Including Tax" ? discountedTotal + newTaxAmount : discountedTotal + newTaxAmount;
  
      // console.log("New Tax Amount:", newTaxAmount, "Final Total Amount:", finalTotal);
  
      updatedItems[index].total_amount = finalTotal.toFixed(2);
  
      let oldDiscountedTotal = itemTotalWithoutTax;
      if (state.addedItems[index]?.price_tax_type === "Including Tax") {
        oldDiscountedTotal /= (1 + oldGst / 100); // Adjust for old GST
      }
      const oldDiscountAmount = calculateDiscount(oldDiscountedTotal, discountValue, discountType);
      oldDiscountedTotal -= oldDiscountAmount;
      
      const oldTaxAmount = calculateTax(oldDiscountedTotal, oldGst, state.addedItems[index]?.price_tax_type === "Including Tax");
      // console.log("Old Discounted Total:", oldDiscountedTotal, "Old Tax Amount:", oldTaxAmount);
      
      // Create a shallow copy of the current tax amounts
      // const newTaxAmounts = { ...state.taxAmounts,oldTaxAmount };

      state.taxAmounts[oldGst]=oldTaxAmount
      
      // Remove old tax amount from the respective GST key
      if (oldGst > 0) {
        if (state.taxAmounts[oldGst]) {
          state.taxAmounts[oldGst] -= oldTaxAmount;
          if (state.taxAmounts[oldGst] <= 0) delete state.taxAmounts[oldGst];
        }
      }
      
      // Add new tax amount to the respective GST key
      if (newGst > 0) {
        if (state.taxAmounts[newGst]) {
          state.taxAmounts[newGst] += newTaxAmount;
        } else {
          state.taxAmounts[newGst] = newTaxAmount;
        }
      }
      
      // Clean up `state.taxAmounts` to ensure all values are correctly rounded
      Object.keys(state.taxAmounts).forEach((key) => {
        if (state.taxAmounts[key] <= 0) {
          delete state.taxAmounts[key];
        } else {
          state.taxAmounts[key] = parseFloat(state.taxAmounts[key].toFixed(2));
        }
      });
      
      // console.log("Updated Tax Amounts:", state.taxAmounts);
      
      // Update state with new items and tax amounts
      setState((prevState) => ({
        ...prevState,
        addedItems: updatedItems,
        taxAmounts:state.taxAmounts,
      }));
      
    },
    [state.addedItems, state.taxAmounts]
  );

  const handleRemoveItem = useCallback(
    (index) => {
      const updatedItems = [...state.addedItems];
      const item = updatedItems[index];

      // Recalculate the tax for the item being removed
      const gst = parseFloat(item.tax) || 0;
      const price = parseFloat(item.price) || 0;
      const itemQuantity = parseFloat(item.quantity) || 0;
      let taxAmount = 0;

      // Tax calculation based on the item's price type
      if (item.price_tax_type === 'Excluding Tax') {
        taxAmount = (price * itemQuantity * gst) / 100;
      } else if (item.price_tax_type === 'Including Tax') {
        const totalWithTax = price * itemQuantity;
        taxAmount = (totalWithTax * gst) / (100 + gst);
      }

      // Update tax amounts
      const newTaxAmounts = { ...state.taxAmounts };
      if (newTaxAmounts[gst]) {
        newTaxAmounts[gst] -= taxAmount;
        if (newTaxAmounts[gst] < 0) {
          newTaxAmounts[gst] = 0; // Ensure no negative tax amounts
        }
      }

      // Remove the item from addedItems
      updatedItems.splice(index, 1);

      // Update the state with the new addedItems and tax amounts
      setState((prevState) => ({
        ...prevState,
        addedItems: updatedItems,
        taxAmounts: newTaxAmounts,
      }));
    },
    [state.addedItems, state.taxAmounts]
  );

  const grandTotal = useMemo(() => {
    // Calculate total of all items and shipping
    const itemsTotal = state.addedItems.reduce((sum, item) => sum + Number(item.total_amount), 0);
    const shipping = Number(state.shippingCost) || 0;
    const shippingGst = (shipping * 12) / 100;

    // Final grand total
    return (itemsTotal + shipping + shippingGst).toFixed(2);
  }, [state.addedItems, state.shippingCost]);

  useEffect(() => {
    const discountAmount = state?.addedItems
    ?.reduce((sum, item) => {
      const discountAmountSubTotal = isNaN(Number(item.discount_amount)) ? 0 : Number(item.discount_amount);
      return sum + discountAmountSubTotal;
    }, 0)
    ?.toFixed(2);
  
  const gstTotal = state?.addedItems
    ?.reduce((sum, item) => {
      const itemSubTotal = isNaN(Number(item.tax_amount)) ? 0 : Number(item.tax_amount);
      return sum + itemSubTotal;
    }, 0)
    ?.toFixed(2);
    const invoiceData = {
      sub_total: state.addedItems.reduce((sum, item) => sum + Number(item.sub_total), 0).toFixed(2),
      shipping_cost: Number(state.shippingCost)?.toFixed(2),
      discount_amount:discountAmount,
      grand_total: grandTotal,
      balance_amount:grandTotal,
      total_gst: gstTotal,
      tcs_amount: 0.00,
      invoice_items: state?.addedItems?.map((item) => ({
        id:item.id,
        item_id: item.item_id,
        quantity: item.quantity,
        unit_id: item.unit_id,
        unit_name: item.unit_name, // Use the unitName here
        price: item.price,
        price_tax_type: item.price_tax_type,
        tax_amount: item.tax_amount,
        tax: item.tax,
        tax_type: 'GST',
        discount: item.discount,
        discount_type: item.discount_type,
        sub_total: item.sub_total,
        total_amount: item.total_amount,
      })),
    };
    // console.log("invoiceData",invoiceData)
    onChildDataChange(invoiceData);
  }, [state.addedItems, state.shippingCost, grandTotal, onChildDataChange]);

  // console.log("addedItems",state.addedItems)
 
  return (
    <div className="row my-3">
      <div className="col-md-12">
        <div className="card custom-card">
          <div className="card-body">
            <table className="table item-table">
              <ProductSelector
                itemList={state?.itemList}
                selectedProduct={state?.selectedProduct}
                handleProductChange={handleProductChange}
                singleDetail={state?.singleDetail}
                handleInputChange={handleInputChange}
                state={state}
                calculateTotal={calculateTotal}
                handleAddItem={handleAddItem}
                isPurchase={false}
                isDiscount={true}
              />
              <tbody>
                <ItemRow
                  addedItems={state?.addedItems}
                  handleAddItem={handleAddItem}
                  handleInputChange={handleInputChange}
                  grandTotal={grandTotal}
                  state={state}
                  handleItemChange={handleItemChange}
                  handleRemoveItem={handleRemoveItem}
                  isPurchase={false}
                  isDiscount={true}
                />
              </tbody>
              <tr>
                <td colspan="10" className="text-right align-middle">
                  <button type="button" className="btn btn-default" onClick={onSubmit}>
                  Update Invoice
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateInvoiceSecond;
