import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreatePurchaseVoucherList, purchaseVouchDelete } from '../store/slices/purchase';
import { ListParties } from '../store/slices/parties';
import Loader from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Table from '../common/Table';
import AdminLayout from './AdminLayout';

const PurchaseBillList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user?.data?.id;
  const Name = user?.data?.company_name;

  const [isLoading, setIsLoading] = useState(false);
  const [purchaseVoucherlist, setPurchaseVoucherlist] = useState([]);
  const [listParties, setListParties] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedParty, setSelectedParty] = useState(null);
  const [columns, setcolumns] = useState([
    { header: 'Bill NO', field: 'bill_no' },
    { header: 'Date', field: 'bill_date' },
    { header: 'Party', field: 'party_name' },
    { header: 'Party Gstn', field: 'party_gstn' },
    { header: 'Amount', field: 'total_amount' },

    {
      header: 'Actions',
      field: 'actions',
      isAction: true,
      actionButtons: [
        { name: 'Edit', className: 'btn-default' },
        { name: 'Delete', className: 'btn-cancel' },
      ],
    },
  ]);

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const partyOptions = listParties.map((party) => ({
    value: party.id,
    label: party.ledger,
  }));

  const fetchParties = async () => {
    try {
      const data = await dispatch(ListParties({ profile_id: id })).unwrap();
      setListParties(data?.data);
    } catch (error) {
      console.log('Error fetching parties:', error.message);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (item) => {
    if (item && item.id) {
      navigate(`/purchase/editpurchasevoucher/${item.id}`);
    } else {
      console.log('Item or item.id is undefined');
    }
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Purchase Voucher?');
    if (confirmDelete) {
      setIsLoading(true);
      dispatch(purchaseVouchDelete({ id: item.id }))
        .unwrap()
        .then((data) => {
          setIsLoading(false);
          hindleReturn();
        })
        .catch(({ message }) => {
          setIsLoading(false);
          console.log(message);
        });
    } else {
      console.log('Deletion canceled');
    }
  };

  const hindleReturn = () => {
    setIsLoading(true);
    dispatch(CreatePurchaseVoucherList({ profile_id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setPurchaseVoucherlist(data?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log(message);
      });
  };

  const fetchPurchaseVoucherList = async () => {
    setIsLoading(true);
    const params = {
      profile_id: id,
      from_date: fromDate,
      to_date: toDate,
      ledger_id: selectedParty?.value || '',
    };
    // console.log(params)
    try {
      const data = await dispatch(CreatePurchaseVoucherList(params)).unwrap();
      setIsLoading(false);
      setPurchaseVoucherlist(data?.data);
    } catch (error) {
      setIsLoading(false);
      setPurchaseVoucherlist([]);
      console.log('Error fetching invoices:', error.message);
    }
  };

  useEffect(() => {
    fetchParties();
    fetchPurchaseVoucherList();
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchPurchaseVoucherList();
  };

  const filtereditemList = purchaseVoucherlist?.filter(
    (party) =>
      party?.bill_no?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.date_added?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.party_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.billing_state?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      party?.total_amount?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  return (
    <AdminLayout>
      {isLoading && <Loader />}
      <div className="row content-body">
        <div className="container-fluid">
          <div className="page-header">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Purchase Voucher</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Purchase</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Purchase Voucher List
                </li>
              </ol>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn ripple btn-default" onClick={() => navigate('/purchase/addpurchasevoucher')}>
                Add Purchase Order
              </button>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-12">
              <div class="card custom-card">
                <div class="card-body">
                  <form onSubmit={handleFilterSubmit} className="row mb-4">
                    <div className="col-md-3">
                      <label>Customer</label>
                      <Select options={partyOptions} placeholder="--Select Customer--" value={selectedParty} onChange={setSelectedParty} />
                    </div>
                    <div className="col-md-3">
                      <label>From Date</label>
                      <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                      <label>To Date</label>
                      <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>
                    <div className="col-md-3 mt-2">
                      <button type="submit" className="btn btn-default mt-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table
                columns={columns}
                data={filtereditemList}
                tableRef={tableRef}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                totalCount={filtereditemList?.length}
                onPageChange={handlePageChange}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSearchChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default PurchaseBillList;
