import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSettingsField,UploadSignature } from "../../store/slices/settings";

const Invoice = ({ data }) => {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state?.settings?.updatesettings);

  const user = JSON.parse(localStorage.getItem("user"));
  const id = user?.data?.id;

  const [files, setFiles] = useState({
    profile_id:id,
    signature: null,
  });
  const [validationErrors, setValidationErrors] = useState({
    signature: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const allowedExtensions = ['.jpg', '.png', '.jpeg', '.webp', '.svg'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes('.' + fileExtension)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Invalid file type.',
        }));
      } else {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        setFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
      }
    }
  };

  const validate = () => {
    const errors = {};
    let isValid = true;

    if (!files.signature) {
      errors.signature = 'Profile image is required.';
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      const submitData = new FormData();

      for (let key in files) {
        if (files[key]) {
          submitData.append(key, files[key]);
        }
      }

      dispatch(UploadSignature(submitData)) // Replace `Updateclients` with the correct action if needed.
        .unwrap()
        .then((data) => {
          console.log(data?.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
console.log("setting?.signature",setting )
  return (
    <div className="">
      <div className="container">
        <div className="row pt-4">
          <div className="col-12 pt-1">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group row my-2">
                  <label className="col-sm-6 col-form-label">Set Invoice Prefix</label>
                  <div className="col-sm-6">
                    <input
                      name="invoice_prefix"
                      type="text"
                      className="form-control form-control-sm"
                      value={setting?.invoice_prefix ?? data?.invoice_prefix}
                      onChange={(e) => {
                        dispatch(
                          updateSettingsField({
                            invoice_prefix: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-sm-6 col-form-label">Enable E-Way Bill Number</label>
                  <div className="col-sm-6">
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        name="chk_ewaybill"
                        className="form-check-input"
                        checked={setting?.invoice_ewaybill ?? data?.invoice_ewaybill}
                        onChange={(e) => {
                          dispatch(
                            updateSettingsField({
                              invoice_ewaybill: e.target.checked,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-sm-6 col-form-label">Set Default Terms and Conditions</label>
                  <div className="col-sm-6">
                    <textarea
                      name="Terms and Conditions"
                      className="form-control"
                      rows="3"
                      onChange={(e) => {
                        dispatch(
                          updateSettingsField({ invoice_terms: e.target.value })
                        );
                      }}
                      value={setting?.invoice_terms ?? data?.invoice_terms}
                    ></textarea>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-sm-6 col-form-label">Upload Signature</label>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        name="signature"
                        type="file"
                        className="form-control"
                        accept=".jpeg, .png, .jpg, .webp, .svg"
                        onChange={handleFileChange}
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn btn-default" onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      {data?.signature &&
                        (/\.(jpeg|jpg|png|webp|svg)$/i.test(data.signature) ? (
                          <img
                            src={data.signature}
                            alt="signature"
                            style={{ width: '100px' }}
                          />
                        ) : (
                          <a href={data.signature} download target="_blank" rel="noreferrer">
                            Download Image
                          </a>
                        ))}
                    </div>
                    {validationErrors.signature && (
                      <div className="text-danger">{validationErrors.signature}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
